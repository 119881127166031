
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { set, ref, onValue } from "firebase/database";


const navigation = [
    { name: 'Dashboard', href: '/dashboard', current: false },
    { name: 'TODO', href: '/todo', current: true },
    { name: 'Share', href: '/share', current: false },
]




export default function Platno() {
    const [paramValue, setParamValue] = useState('');
    const [currentValue, setCurrentValue] = useState('');

    useEffect(() => {
        // Continuously read the parameter value
        const paramRef = ref(db, 'parameters/parameter');
        onValue(paramRef, (snapshot) => {
            const value = snapshot.val();
            setCurrentValue(value);
        });

        // Cleanup function to unsubscribe when component unmounts
        return () => {
            // Unsubscribe from Firebase listener
            // (Optional, but a good practice to avoid memory leaks)
            // off(paramRef);
        };
    }, []);

    const setParam = () => {
        setParamValue(!paramValue);
        set(ref(db, 'parameters/parameter'), paramValue);
    };

    return (
        <div className=" w-full flex justify-center items-center flex-col">

            <h1 className=" m-4 text-3xl border-blue-600 border-8 p-4 rounded-lg">{currentValue ? ("Zapnuto") : ("Vypnuto")}</h1>
            <button className="flex w-1/2 m-5 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={setParam}>Switch</button>
            {currentValue ? (<div className=' bg-lime-500 w-5/6 h-[60vh]'></div>) : (<div className=' bg-red-500 w-5/6 h-[60vh]'></div>)}
        </div >
    );

}