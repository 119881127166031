import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

import React, { useEffect, useState } from "react";
import { auth, db, signout } from "../firebase";
import { useNavigate } from "react-router-dom";
import { uid } from "uid";
import { set, ref, onValue, remove, update } from "firebase/database";
import { useAuth } from "../firebase";
import { getTime, getDate } from "../components/Time";
import { ReactComponent as Green } from "../img/checkg.svg";
import { ReactComponent as Red } from "../img/circle-xmark-solid.svg";
import { ReactComponent as Trash } from "../img/trash-solid.svg";
import { ReactComponent as Pen } from "../img/pen-to-square-solid.svg";
import { toast } from 'react-toastify';

const navigation = [
    { name: 'Dashboard', href: '/dashboard', current: false },
    { name: 'TODO', href: '/todo', current: true },
    { name: 'Share', href: '/share', current: false },
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Todo() {
    const navigate = useNavigate();
    const [todo, addTodo] = useState("");
    const [todos, readTodo] = useState([]);
    const [edit, setEdit] = useState(false);
    const [tempUidd, setTempUidd] = useState("");

    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [formatedDate, setFormatedDate] = useState("");

    const [todoDate, setTodoDate] = useState(getDate());
    const [todoTime, setTodoTime] = useState(getTime());

    const limitDate = getDate();

    useEffect(() => {
        const interval = setInterval(() => {
            setDate(getDate());
            setTime(getTime());
            setFormatedDate(fomrating(getDate()));
        }
            , 1000);
        return () => clearInterval(interval);
    }, [])

    const timeLeft3 = (todo) => {
        const actualDateTime = (new Date()).getTime();
        const todoDateTime = (new Date(todo.date + 'T' + todo.time)).getTime();
        console.log((new Date(todo.date + 'T' + todo.time)).getTime());
        console.log((new Date()).getTime());

        console.log(todoDateTime);
        console.log(actualDateTime);

        const difference = todoDateTime - actualDateTime;

        if (difference <= 0) {
            return <h2 className={classNames(todo.status ? ("text-white") : ("text-red-400"))}>{todo.status ? ("Completed") : ("Time is up!")}</h2>;
        }

        console.log(difference);

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

        console.log(days + " " + hours + " " + minutes);

        if (days == 0 && hours > 0) {
            return `${hours} hours, ${minutes} minutes`;
        } else if (days == 0 && hours == 0) {
            return `${minutes} minutes`;
        } else {
            return `${days} d ${hours} h ${minutes} m`;
        }
    }



    const fomrating = (dddd) => {
        const date = new Date(dddd);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}. ${month}. ${year}`;
    }
    const formating = (todo) => {
        const date = new Date(todo.date);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}. ${month}. ${year}`;
    }



    const currentUser = useAuth();

    useEffect(() => {
        if (currentUser) {
            onValue(ref(db, `/${currentUser.uid}`), snapshot => {
                readTodo([]);
                const data = snapshot.val();
                if (data !== null) {
                    const sortedData = sortByDateAndTime(Object.values(data));
                    sortedData.forEach(todo => {
                        readTodo(oldArray => [...oldArray, todo]);
                        console.log(todos);
                    });
                }
            });
        }
    }, [currentUser]);

    function sortByDateAndTime(array) {
        array.sort((a, b) => {
            const dateA = new Date(`${a.date}T${a.time}`);
            const dateB = new Date(`${b.date}T${b.time}`);

            return dateA - dateB;
        });

        return array;
    }


    const handleSignOut = () => {
        signout().then(() => navigate("/")).catch((error) => {
            alert(error);
        });
    };




    //add
    const writeTo = () => {
        const uidd = uid();
        if (todo !== "") {
            set(ref(db, `/${currentUser.uid}/${uidd}`), {
                todo: todo,
                time: todoTime,
                date: todoDate,
                uidd: uidd,
                status: false,
            })
        } else { toast.error("Please enter a todo"); }
        addTodo("");
        setTodoTime(getTime());
        setTodoDate(getDate());
    };
    //update
    const handleEdit = (todo) => {
        setEdit(true);
        addTodo(todo.todo);
        setTempUidd(todo.uidd);
        setTodoDate(todo.date);
        setTodoTime(todo.time);
    }
    const handleEditConfirm = () => {
        update(ref(db, `/${currentUser.uid}/${tempUidd}`), {
            todo: todo,
            tempUidd: tempUidd,
            date: todoDate,
            time: todoTime,
        });
        setEdit(false);
        addTodo("");
        setTodoTime(getTime());
        setTodoDate(getDate());
    }

    const handleAddTodo = (e) => {
        addTodo(e.target.value);
    }

    //update
    const updateStatus = (todo) => {
        update(ref(db, `/${currentUser.uid}/${todo.uidd}`), {
            status: !todo.status,
        });
    }

    //delete
    const handleDelete = (uid) => {
        remove(ref(db, `/${currentUser.uid}/${uid}`));
    }

    const handleDate = (e) => {
        setTodoDate(e.target.value);
    }
    const handleTime = (e) => {
        setTodoTime(e.target.value);
    }

    return (
        <>
            {currentUser ? (<>
                <div className="min-h-full">
                    <Disclosure as="nav" className="bg-gray-800">
                        {({ open }) => (
                            <>
                                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                    <div className="flex h-16 items-center justify-between">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                                <img
                                                    className="h-8 w-8"
                                                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                                                    alt="Your Company"
                                                />
                                            </div>
                                            <div className="hidden md:block">
                                                <div className="ml-10 flex items-baseline space-x-4">
                                                    {navigation.map((item) => (
                                                        <a
                                                            key={item.name}
                                                            href={item.href}
                                                            onClick={item.onClick}
                                                            className={classNames(
                                                                item.current
                                                                    ? 'bg-gray-900 text-white'
                                                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                                'rounded-md px-3 py-2 text-sm font-medium'
                                                            )}
                                                            aria-current={item.current ? 'page' : undefined}
                                                        >
                                                            {item.name}
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hidden md:block">
                                            <div className="ml-4 flex items-center md:ml-6">
                                                <button
                                                    type="button"
                                                    className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                                >
                                                    <span className="sr-only">View notifications</span>
                                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>

                                                {/* Profile dropdown */}
                                                <Menu as="div" className="relative ml-3">
                                                    <div>
                                                        <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                            <span className="sr-only">Open user menu</span>
                                                            <img className="h-8 w-8 rounded-full" src={currentUser.photoURL} alt="" />
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <a className='block px-4 py-2 text-sm text-gray-700' onClick={() => handleSignOut()}>Sign out</a>
                                                            <a className='block px-4 py-2 text-sm text-gray-700' onClick={() => navigate("/settings")}>Settings</a>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                        </div>
                                        <div className="-mr-2 flex md:hidden">
                                            {/* Mobile menu button */}
                                            <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                <span className="sr-only">Open main menu</span>
                                                {open ? (
                                                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                                ) : (
                                                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                                )}
                                            </Disclosure.Button>
                                        </div>
                                    </div>
                                </div>

                                <Disclosure.Panel className="md:hidden">
                                    <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                                        {navigation.map((item) => (
                                            <Disclosure.Button
                                                key={item.name}
                                                as="a"
                                                href={item.href}
                                                className={classNames(
                                                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'block rounded-md px-3 py-2 text-base font-medium'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        ))}
                                    </div>
                                    <div className="border-t border-gray-700 pb-3 pt-4">
                                        <div className="flex items-center px-5">
                                            <div className="flex-shrink-0">
                                                <img className="h-10 w-10 rounded-full" src={currentUser.photoURL} alt="" />
                                            </div>
                                            <div className="ml-3">
                                                <div className="text-base font-medium leading-none text-white">{currentUser.displayName}</div>
                                                <div className="text-sm font-medium leading-none text-gray-400">{currentUser.email}</div>
                                            </div>
                                            <button
                                                type="button"
                                                className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                            >
                                                <span className="sr-only">View notifications</span>
                                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="mt-3 space-y-1 px-2">
                                            <a className='block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white' onClick={() => navigate("/settings")}>Settings</a>
                                            <a className='block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white' onClick={() => handleSignOut()}>Sign out</a>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>

                    <header className="bg-white shadow">
                        <div className="mx-auto flex justify-between max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Todo list</h1>
                            <div className='flex justify-center items-center gap-6 text-2xl font-bold tracking-tight text-gray-900'>
                                <h1>{time}</h1>
                                <h1>{formatedDate}</h1>
                            </div>
                        </div>
                    </header>
                    <main>
                        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                            <div className='flex max-[640px]:flex-col max-[640px]:gap-4 justify-center items-center'>
                                <input className='max-[640px]:rounded-2xl sm:rounded-l-2xl' type="text" value={todo} onChange={handleAddTodo} placeholder='todo' />
                                <input className='max-[640px]:rounded-2xl sm:rounded-none' type="time" value={todoTime} onChange={handleTime} name="" id="" />
                                <input className='max-[640px]:rounded-2xl sm:rounded-r-2xl' type="date" value={todoDate} min={limitDate} onChange={handleDate} name="" id="" />
                                {edit ? (<button onClick={handleEditConfirm} className='rounded-md ml-3 bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>Confirm</button>) : (<button className='rounded-md ml-3 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' onClick={writeTo}>add</button>)}
                            </div>
                            <div className=' border-2 w-4/5 rounded-full m-auto my-4'></div>
                            <div className="p-4 rounded-3xl ring-1 m-auto ring-gray-200 w-11/12">
                                <div className=' font-semibold '>
                                    {todos.map((todo) => (
                                        <div key={todo.uidd}>
                                            <div className={classNames(todo.status ? ("") : (""), "border-t-2 w-4/5 m-auto mt-1")}></div>
                                            <div className={classNames(todo.status ? ("bg-green-500/50 text-white") : (""), " gap-4 flex justify-evenly items-center m-1 p-5 rounded-2xl max-[640px]:flex-col max-[640px]:text-center")}>
                                                <div className='flex flex-col min-[640px]:w-3/6'>
                                                    <h2 className=' text-xl'>{todo.todo}</h2>
                                                    <div className='flex max-[640px]:m-auto justify-start gap-2 items-center'>
                                                        <h3>{todo.time}</h3>
                                                        {todo.date && (<h3>{formating(todo)}</h3>)}
                                                    </div>
                                                </div>
                                                <div className="min-[640px]:w-2/6 text-base sm:flex sm:flex-row">
                                                    {todo.date && (timeLeft3(todo))}
                                                </div>
                                                <div className='flex justify-between max-[640px]:flex-row max-[640px]:gap-4 items-center min-[640px]:w-1/6'>
                                                    <button onClick={() => updateStatus(todo)} className={classNames(todo.status ? (" fill-white w-6 h-6") : ("fill-red-600 w-6 h-6"))}><Green /></button>
                                                    <button className={classNames(todo.status && "fill-white", "w-6 h-6")} onClick={() => handleEdit(todo)}><Pen /></button>
                                                    <button className={classNames(todo.status && "fill-white", "w-6 h-6")} onClick={() => handleDelete(todo.uidd)}><Trash /></button>
                                                </div>
                                            </div>
                                            <div className={classNames(todo.status ? ("") : (""), "border-b-2 w-4/5 m-auto mb-1")}></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </>) : (<>
                <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                    <div className="text-center">
                        <p className="text-base font-semibold text-indigo-600"></p>
                        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Not logged in!</h1>
                        <p className="mt-6 text-base leading-7 text-gray-600">Please log in before accessing this page.</p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <a
                                href="/login"
                                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Login
                            </a>
                            <a href="/register" className="text-sm font-semibold text-gray-900">
                                Register <span aria-hidden="true">&rarr;</span>
                            </a>
                        </div>
                    </div>
                </main>
            </>)}
        </>
    )
}