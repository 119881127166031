import React, { Component } from 'react'
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useState } from 'react';

export default function Qr() {
    const [results, setResults] = useState('No result');

    const previewStyle = {
        width: "320px",
        height: "320px",
        margin: '0 auto',
    }
    return (
        <div>
            <h1>{results}</h1>
            <QrScanner width="320px" height="320px"
                onDecode={(result) => setResults(result)}
                onError={(error) => console.log(error?.message)}
                containerStyle={previewStyle}
            />
        </div>
    )
}