// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
//14:33
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signOut, signInWithEmailAndPassword, updateProfile, sendPasswordResetEmail, updateEmail } from "firebase/auth";
import { getDownloadURL, getStorage, ref, uploadBytes, deleteObject } from "firebase/storage";
import { getDatabase, set, ref as reff, remove as removeDB } from "firebase/database";
import { uid } from "uid";
import { useEffect, useState } from "react";
import { async } from "@firebase/util";
import { waitFor } from "@testing-library/react";
import { toast } from "react-toastify";
import { useNavigate as navigate } from "react-router-dom";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDBoBkSEd8lLqNnjBBfC8UWw8GIM4MPPbM",
    authDomain: "panel-d8543.firebaseapp.com",
    projectId: "panel-d8543",
    storageBucket: "panel-d8543.appspot.com",
    messagingSenderId: "779388765129",
    appId: "1:779388765129:web:3f3a880cd0550b2dbc1e18",
    measurementId: "G-X7QXHPBJVN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth();
const storage = getStorage();
export const db = getDatabase(app);


export function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
}

export function signin(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
}
//signout function
export function signout() {
    return signOut(auth);
}

export function resetPassword(email) {
    sendPasswordResetEmail(auth, email).then(() => {
        toast.success("Password reset email sent!");
        //navigate("/login");
    }).catch((error) => {
        toast.error(error.message);
    });
}

export function newEmail(currentUser, email) {
    return updateEmail(currentUser, email).catch((error) => {
        toast.error(error.message);
    });
}


//costume hook
export function useAuth() {
    const [currentUser, setCurrentUser] = useState("");
    useEffect(() => {
        const unsub = onAuthStateChanged(auth, user => setCurrentUser(user))
        return unsub;
    }, [])
    return currentUser;
}

//storage
export async function upload(file, currentUser) {
    const fileRef = ref(storage, "/images/" + currentUser.uid + ".png");

    const snapshot = await uploadBytes(fileRef, file);

    const photoURL = await getDownloadURL(fileRef);

    await updateProfile(currentUser, { photoURL });

}

export async function share(file, currentUser, note, setLoading) {
    const uidd = uid();
    const filename = file.name.replace(/ /g, "_");
    console.log(filename);
    const fileRef = ref(storage, "/share/" + currentUser.uid + "/" + uidd + "/" + filename);

    const snapshot = await uploadBytes(fileRef, file);

    const shareLink = await getDownloadURL(fileRef);

    await set(reff(db, `/share/${currentUser.uid}/${uidd}`), {
        filename: filename,
        shareLink: shareLink,
        note: note,
        uidd: uidd,
    });

    toast.success("File shared successfully!");
    setLoading(false);

}

export async function deleteFile(currentUser, filename, uidd) {
    const fileRef = ref(storage, "/share/" + currentUser.uid + "/" + uidd + "/" + filename);

    await deleteObject(fileRef);

    const dbRef = reff(db, `/share/${currentUser.uid}/${uidd}`);

    await removeDB(dbRef);

    toast.success("File deleted successfully!");
}

export async function defPhotSet(photoURL, currentUser) {
    updateProfile(currentUser, { photoURL });
}