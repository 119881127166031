import React, { useState, useEffect } from 'react';
import { useAuth, signout, signin } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updatePassword } from 'firebase/auth';


export default function PassChange() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [newPass, setNewPass] = useState("");
    const navigate = useNavigate();
    const currentUser = useAuth();
    const [go, setGo] = useState(false);

    const enterPressed = (e) => {
        if (e.key === "Enter") {
            handleSubmit();
        }
    }

    const moveToNewPass = (e) => {
        if (e.key === "Enter") {
            document.getElementById("newPass").focus();
        }
    }

    useEffect(() => {
        if (currentUser && go) {
            emailUpdate();
        } else if (currentUser) {
            setEmail(currentUser.email);
            signout();
        }
    }, [currentUser]);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }
    const handleNewPassChange = (e) => {
        setNewPass(e.target.value);
    }

    const emailUpdate = async () => {
        updatePassword(currentUser, newPass).then(() => {
            toast.success("Password changed successfully");
            navigate("/login");
        }
        ).catch((error) => {
            toast.error(error.message);
        }
        );
    }

    const handleSubmit = async () => {
        await signin(email, password).then(() => {
            setGo(true);
        }).catch((error) => {
            toast.error(error.message);
        }
        );
    }

    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl sm:text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Password change</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        You have been sign out for security reason.
                        Fill all the fields to change your email.
                    </p>
                </div>
                <div className="mx-auto w-3/6 max-[600px]:w-11/12 mt-16 rounded-3xl ring-1 ring-gray-200 flex flex-col items-center justify-evenly ">
                    <div className='m-3 w-11/12'>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Email address
                        </label>
                        <div className="mt-2">
                            <input
                                disabled={true}
                                value={email}
                                type="email"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div className='m-3 w-11/12'>
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                            Password
                        </label>
                        <div className="mt-2">
                            <input
                                onKeyDown={moveToNewPass}
                                onChange={handlePasswordChange}
                                type="password"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div className='m-3 w-11/12'>
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                            New password
                        </label>
                        <div className="mt-2">
                            <input
                                id="newPass"
                                onChange={handleNewPassChange}
                                onKeyDown={enterPressed}
                                type="password"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div className='w-11/12 my-4 border-t-4 border-slate-400 rounded-full'></div>
                    <div className='flex justify-between items-center'>
                        <div className='mb-4 mr-4'>
                            <button
                                onClick={() => navigate("/login")}
                                className="flex w-full justify-center rounded-md bg-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Cancel
                            </button>
                        </div>
                        <div className='mb-4'>
                            <button
                                onClick={handleSubmit}
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Change password
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}