import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, signout, db } from "../firebase";
import { ref, onValue } from "firebase/database";


const navigation = [
  { name: 'Dashboard', href: '/dashboard', current: true },
  { name: 'TODO', href: '/todo', current: false },
  { name: 'Share', href: '/share', current: false },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function notifyUser(notyText = "Thx for Enable") {
  if (!("Notification" in window)) {
    alert("Browser not noty")
  } else if (Notification.permission === "granted") {
    const notification = new Notification(notyText);
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      if (Notification.permission === "granted") {
        const notification = new Notification(notyText);
      }
    })
  }
}

export default function Dashboard() {

  const navigate = useNavigate();
  const [todos, readTodo] = useState([]);

  const [completed, setCompleted] = useState(0);
  const [toThisDay, setToThisDay] = useState(0);
  const [total, setTotal] = useState(0);
  const [urgent, setUrgent] = useState(0);
  const [failed, setFailed] = useState(0);

  const currentUser = useAuth();


  useEffect(() => {
    if (currentUser) {
      onValue(ref(db, `/${currentUser.uid}`), snapshot => {
        readTodo([]);
        const data = snapshot.val();
        if (data !== null) {
          const sortedData = sortByDateAndTime(Object.values(data));
          sortedData.forEach(todo => {
            readTodo(oldArray => [...oldArray, todo]);
          });
        }
      });
    }
  }, [currentUser]);

  function sortByDateAndTime(array) {
    array.sort((a, b) => {
      const dateA = new Date(`${a.date}T${a.time}`);
      const dateB = new Date(`${b.date}T${b.time}`);

      return dateA - dateB;
    });
    console.log(array);
    setCompleted(array.filter(todo => todo.status).length);
    setTotal(array.length);
    setFailed(array.filter(todo => (new Date(`${todo.date}T${todo.time}`)).getTime() - (new Date()).getTime() < 0 && !todo.status).length);
    setToThisDay(array.filter(todo => (new Date(`${todo.date}T${todo.time}`)).getTime() - (new Date()).getTime() <= (1000 * 60 * 60 * 24) && !todo.status && new Date(`${todo.date}T${todo.time}`).getTime() - (new Date()).getTime() > (1000 * 60 * 60)).length);
    setUrgent(array.filter(todo => ((new Date(`${todo.date}T${todo.time}`)).getTime() - (new Date()).getTime()) <= (1000 * 60 * 60) && !todo.status && new Date(`${todo.date}T${todo.time}`).getTime() - (new Date()).getTime() > (0)).length);
    return array;
  }


  const timeWelcome = () => {
    const hour = new Date().getHours();
    if (hour < 12) {
      return "Good Morning";
    }
    else if (hour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  }

  const handleSignOut = async () => {
    signout().then(() => navigate("/")).catch((error) => {
      alert(error);
    });
  }

  return (
    <>
      {currentUser ? (<>
        <div className="min-h-full">
          <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="flex h-16 items-center justify-between">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <img
                          className="h-8 w-8"
                          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                          alt="Your Company"
                        />
                      </div>
                      <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4">
                          {navigation.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              onClick={item.onClick}
                              className={classNames(
                                item.current
                                  ? 'bg-gray-900 text-white'
                                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'rounded-md px-3 py-2 text-sm font-medium'
                              )}
                              aria-current={item.current ? 'page' : undefined}
                            >
                              {item.name}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-4 flex items-center md:ml-6">
                        <button
                          type="button"
                          className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        >
                          <span className="sr-only">View notifications</span>
                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        {/* Profile dropdown */}
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                              <span className="sr-only">Open user menu</span>
                              <img className="h-8 w-8 rounded-full" src={currentUser.photoURL} alt="" />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <a className='block px-4 py-2 text-sm text-gray-700' onClick={() => handleSignOut()}>Sign out</a>
                              <a className='block px-4 py-2 text-sm text-gray-700' onClick={() => navigate("/settings")}>Settings</a>
                            </div>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="md:hidden">
                  <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'block rounded-md px-3 py-2 text-base font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className="border-t border-gray-700 pb-3 pt-4">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={currentUser.photoURL} alt="" />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium leading-none text-white">{currentUser.displayName}</div>
                        <div className="text-sm font-medium leading-none text-gray-400">{currentUser.email}</div>
                      </div>
                      <button
                        type="button"
                        className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-3 space-y-1 px-2">
                      <a className='block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white' onClick={() => navigate("/settings")}>Settings</a>
                      <a className='block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white' onClick={() => handleSignOut()}>Sign out</a>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <header className="bg-white shadow">
            <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">Dashboard</h1>
            </div>
          </header>
          <main>
            <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
              <h1 className=' text-2xl font-semibold max-[640px]:text-center'>{timeWelcome() + " " + currentUser.displayName}</h1>
              <div>
                <h2>Completed: {completed}</h2>
                <h2>Total: {total}</h2>
                <h2>Urgent: {urgent}</h2>
                <h2>To this day {toThisDay}</h2>
                <h2>Failed {failed}</h2>
                <button onClick={notifyUser}>Push notifications</button>
              </div>
            </div>
          </main>
        </div>
      </>) : (<>
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
            <p className="text-base font-semibold text-indigo-600"></p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Not logged in!</h1>
            <p className="mt-6 text-base leading-7 text-gray-600">Please log in before accessing this page.</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/login"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Login
              </a>
              <a href="/register" className="text-sm font-semibold text-gray-900">
                Register <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </main>
      </>)}
    </>
  )
}