import React, { useState, useEffect } from "react";
import { useAuth, resetPassword } from "../firebase";
import { useNavigate as navigate } from "react-router-dom";
import { toast } from "react-toastify";


export default function Forgot() {
    const [email, setEmail] = useState("");
    const currentUser = useAuth();

    const enterPressed = (e) => {
        if (e.key === "Enter") {
            handleRemember();
        }
    };

    useEffect(() => {
        if (currentUser) {
            navigate("/dashboard");
        }
    }, [currentUser]);

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleRemember = async () => {
        resetPassword(email)
    }

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        className="mx-auto h-10 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                        alt="Your Company"
                    />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Forget your password?
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <div className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={handleEmail}
                                    onKeyDown={enterPressed}
                                    type="email"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                onClick={handleRemember}
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Request password reset
                            </button>
                        </div>
                    </div>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Remember now ?{' '}
                        <a href="/login" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                            Sign in!
                        </a>
                    </p>
                </div>
            </div>
        </>
    );
}