import logo from './logo.svg';
import './App.css';
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Stránky
import Login from './pages/Login';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Register from './pages/Register';
import Forgot from './pages/Forgot';
import Settings from './pages/Settings';
import Todo from './pages/Todo';
import LoginChange from './pages/LoginChange';
import Help from './pages/Help';
import PassChange from './pages/PassChange';
import NotFound from './pages/NotFound';
import Share from './pages/Share';
import Qr from './pages/Qr';
import Platno from './pages/Platno';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/forgot' element={<Forgot />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/emailchange' element={<LoginChange />} />
        <Route path='/passwordchange' element={<PassChange />} />
        <Route path='/todo' element={<Todo />} />
        <Route path='/share' element={<Share />} />
        <Route path='*' element={<NotFound />} />
        <Route path='/help' element={<Help />} />
        <Route path='/hovno' element={<Qr />} />
        <Route path='/platno' element={<Platno />} />
      </Routes>
    </div>
  );
}

export default App;
