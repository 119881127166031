import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { useAuth, upload, defPhotSet, signin, signout, newEmail } from '../firebase';
import { useEffect, useState } from 'react';
import { updateProfile } from 'firebase/auth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { set } from 'firebase/database';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Settings() {
    const currentUser = useAuth();
    const navigate = useNavigate();
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [prewiew, setPrewiew] = useState("https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1200px-User-avatar.svg.png");
    const defaultPhoto = "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1200px-User-avatar.svg.png";

    useEffect(() => {
        if (currentUser) {
            setEmail(currentUser.email);
            console.log(currentUser);
            setUsername(currentUser.displayName);
        }
        if (currentUser?.displayName) {
            setEmail(currentUser.email);
            setPrewiew(currentUser.photoURL);
        }
    }, [currentUser]);

    const uploadProfile = async () => {
        if (username !== null && !document.getElementById("Dname").value.length == 0) {
            await updateProfile(currentUser, {
                displayName: username,
                photoURL: prewiew,
            }).then(() => {
                console.log("success");
            }).catch((error) => {
                toast(error.message);
            });
            if (!document.getElementById("file").files.length == 0) {
                handleUpload();
            } else {
                navigate("/dashboard");
                //window.location.reload();
            }
            navigate("/dashboard");
        } else {
            toast.error("Fill in all fields");
        }
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handleChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
        setPrewiew(URL.createObjectURL(e.target.files[0]));
    };

    const handleUpload = () => {
        upload(image, currentUser);
    };

    const startEmailChange = () => {
        navigate("/emailchange");
    };

    const startPassChange = () => {
        navigate("/passwordchange");
    }

    return (
        <>
            {currentUser ? (<>
                <div className=' flex items-center justify-center flex-col h-[100vh]'>
                    <div className='flex items-center justify-center w-4/5 flex-col'>
                        <div className="space-y-12 w-4/5">
                            <div className="border-y border-gray-900/10 py-12">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-4">
                                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                            Username
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input
                                                    id='Dname'
                                                    onChange={handleUsernameChange}
                                                    value={username}
                                                    type="text"
                                                    autoComplete="username"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder="Johny"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                            Email
                                        </label>
                                        <div className="mt-2 flex">
                                            <div className="flex grow rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input
                                                    disabled={true}
                                                    value={email}
                                                    type="email"
                                                    autoComplete="email"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder="johny@kreativnikopec.eu"
                                                />
                                            </div>
                                            <button disabled={!currentUser?.displayName} onClick={startEmailChange} className={classNames(
                                                !currentUser?.displayName ? 'rounded-md ml-3 bg-slate-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ' : 'rounded-md ml-3 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                            )}>Change</button>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                            Password
                                        </label>
                                        <div className="mt-2 flex">
                                            <div className="flex grow rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input
                                                    disabled={true}
                                                    value="*********"
                                                    type="password"
                                                    autoComplete="password"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder="*********"
                                                />
                                            </div>
                                            <button disabled={!currentUser?.displayName} onClick={startPassChange} className={classNames(
                                                !currentUser?.displayName ? 'rounded-md ml-3 bg-slate-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ' : 'rounded-md ml-3 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                            )}>Change</button>
                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                                            Photo
                                        </label>
                                        <div className="mt-2 flex max-[600px]:flex-col items-center gap-x-3">
                                            <img src={prewiew} className="h-12 w-12 rounded-full max-[600px]:h-28 max-[600px]:w-28 max-[600px]:mb-3 text-gray-300" aria-hidden="true" />
                                            <input id='file' onChange={handleChange} type="file" accept="image/png, image/jpeg" className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100" />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="my-6 flex items-center justify-end gap-x-6">
                            <button type="button" disabled={!currentUser.displayName} onClick={() => navigate("/dashboard")} className="text-sm font-semibold leading-6 text-gray-900">
                                Cancel
                            </button>
                            <button
                                onClick={uploadProfile}
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </>) : (<>
                <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                    <div className="text-center">
                        <p className="text-base font-semibold text-indigo-600"></p>
                        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Not logged in!</h1>
                        <p className="mt-6 text-base leading-7 text-gray-600">Please log in before accessing this page.</p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <a
                                href="/login"
                                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Login
                            </a>
                            <a href="/register" className="text-sm font-semibold text-gray-900">
                                Register <span aria-hidden="true">&rarr;</span>
                            </a>
                        </div>
                    </div>
                </main>
            </>)}


        </>
    )
}
