import React, { useState } from 'react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function Help() {
    const [a, setA] = useState(false);
    const [b, setB] = useState(false);
    const [c, setC] = useState(false);
    const [d, setD] = useState(false);

    const [one, set1] = useState(false);
    const [two, set2] = useState(false);
    const [three, set3] = useState(false);
    const [four, set4] = useState(false);
    const [five, set5] = useState(false);
    const [six, set6] = useState(false);
    const [seven, set7] = useState(false);

    const handleA = () => {
        setA(!a)
    }
    const handleB = () => {
        setB(!b)
    }
    const handleC = () => {
        setC(!c)
    }
    const handleD = () => {
        setD(!d)
    }



    return (
        <div className="flex justify-evenly items-center gap-40 h-[80vh] mb-10  flex-col">
            <h1>Help</h1>
            <div>
                <h1>a: {a ? (1) : (0)}</h1>
                <h1>b: {b ? (1) : (0)}</h1>
                <h1>c: {c ? (1) : (0)}</h1>
                <h1>d: {d ? (1) : (0)}</h1>
            </div>

            <div className=" w-52 h-[416px] border-slate-950 border-2">
                <div className="flex justify-center">
                    <div className={classNames(one ? "bg-red-500 w-44 h-5" : "bg-black w-44 h-5")}></div>
                </div>
                <div className="flex justify-between">
                    <div className="w-5 h-44 bg-black"></div>
                    <div className="w-5 h-44 bg-black"></div>
                </div>
                <div className="flex justify-center">
                    <div className=" w-44 h-5 bg-black "></div>
                </div>
                <div className="flex justify-between">
                    <div className="w-5 h-44 bg-black"></div>
                    <div className="w-5 h-44 bg-black"></div>
                </div>
                <div className="flex justify-center">
                    <div className=" w-44 h-5 bg-black "></div>
                </div>
            </div>

            <div className="flex justify-center gap-2">
                <input type="checkbox" onChange={handleA} name="" id="" />
                <input type="checkbox" onChange={handleB} name="" id="" />
                <input type="checkbox" onChange={handleC} name="" id="" />
                <input type="checkbox" onChange={handleD} name="" id="" />
            </div>
        </div>
    )
}